import type { MutationFunction, QueryFunction } from '@tanstack/react-query';
import type { AxiosRequestConfig } from 'axios';
import axios from 'axios';
import type {
  TOrderData,
  TOrderResponse,
  TProduct,
  TProductListItem,
} from 'types';
import type { TExchangeRate } from 'types/exchange-rate.types';
import type { TSeller } from 'types/seller.types';

// import { API_URL } from '../constants/urls';
// locale "en"
import {
  productList as en_learnProektProductList,
  product_1 as en_learnProektProduct1,
  product_2 as en_learnProektProduct2,
  product_3 as en_learnProektProduct3,
  seller_info as en_learnProektInfo,
} from './seller/en/LearnProekt';
import {
  productList as en_ninetyNineProductList,
  product_1 as en_ninetyNineProduct1,
  product_2 as en_ninetyNineProduct2,
  seller_info as en_ninetyNineInfo,
} from './seller/en/NinetyNine';
import {
  productList as en_skyengProductList,
  product_1 as en_skyengProduct1,
  seller_info as en_skyengInfo,
} from './seller/en/SKYENG';
import {
  productList as en_skillFactoryProductList,
  product_1 as en_skillFactoryProduct1,
  product_2 as en_skillFactoryProduct2,
  product_3 as en_skillFactoryProduct3,
  seller_info as en_skillFactoryInfo,
} from './seller/en/SkillFactory';
import {
  productList as en_sychevProductList,
  product_1 as en_sychevProduct1,
  product_2 as en_sychevProduct2,
  product_3 as en_sychevProduct3,
  seller_info as en_sychevInfo,
} from './seller/en/Sychev';
import {
  productList as en_yasnoProductList,
  product_1 as en_yasnoProduct1,
  product_2 as en_yasnoProduct2,
  product_3 as en_yasnoProduct3,
  seller_info as en_yasnoInfo,
} from './seller/en/Yasno';
import {
  productList as en_nethouseProductList,
  product_1 as en_nethouseProduct1,
  product_2 as en_nethouseProduct2,
  product_3 as en_nethouseProduct3,
  seller_info as en_nethouseInfo,
} from './seller/en/nethouse';
import {
  productList as en_shatskayaProductList,
  product_1 as en_shatskayaProduct1,
  product_2 as en_shatskayaProduct2,
  product_3 as en_shatskayaProduct3,
  seller_info as en_shatskayaInfo,
} from './seller/en/shatskaya';
// locale "hy" - default
import {
  productList as learnProektProductList,
  product_1 as learnProektProduct1,
  product_2 as learnProektProduct2,
  product_3 as learnProektProduct3,
  seller_info as learnProektInfo,
} from './seller/hy/LearnProekt';
import {
  productList as ninetyNineProductList,
  product_1 as ninetyNineProduct1,
  product_2 as ninetyNineProduct2,
  seller_info as ninetyNineInfo,
} from './seller/hy/NinetyNine';
import {
  productList as skyengProductList,
  product_1 as skyengProduct1,
  seller_info as skyengInfo,
} from './seller/hy/SKYENG';
import {
  productList as skillFactoryProductList,
  product_1 as skillFactoryProduct1,
  product_2 as skillFactoryProduct2,
  product_3 as skillFactoryProduct3,
  seller_info as skillFactoryInfo,
} from './seller/hy/SkillFactory';
import {
  productList as sychevProductList,
  product_1 as sychevProduct1,
  product_2 as sychevProduct2,
  product_3 as sychevProduct3,
  seller_info as sychevInfo,
} from './seller/hy/Sychev';
import {
  productList as yasnoProductList,
  product_1 as yasnoProduct1,
  product_2 as yasnoProduct2,
  product_3 as yasnoProduct3,
  seller_info as yasnoInfo,
} from './seller/hy/Yasno';
import {
  productList as nethouseProductList,
  product_1 as nethouseProduct1,
  product_2 as nethouseProduct2,
  product_3 as nethouseProduct3,
  seller_info as nethouseInfo,
} from './seller/hy/nethouse';
import {
  productList as shatskayaProductList,
  product_1 as shatskayaProduct1,
  product_2 as shatskayaProduct2,
  product_3 as shatskayaProduct3,
  seller_info as shatskayaInfo,
} from './seller/hy/shatskaya';

const MOCK_AMD = 0.25333;
const convertList = (
  list: TProductListItem[],
  exchangeRate: unknown,
): TProductListItem[] =>
  list.map(item => ({
    ...item,
    price: +(item.price / +exchangeRate).toFixed(2),
    costInRubles: item.price,
    costInDollars: +(item.price / +exchangeRate).toFixed(2),
    exchangeRate: +exchangeRate,
  }));
const productsMap: Record<string, Record<string, TProduct>> = {
  hy: {
    'nethouse-product-id-1': nethouseProduct1.data,
    'nethouse-product-id-2': nethouseProduct2.data,
    'nethouse-product-id-3': nethouseProduct3.data,
    'shatskaya-product-id-1': shatskayaProduct1.data,
    'shatskaya-product-id-2': shatskayaProduct2.data,
    'shatskaya-product-id-3': shatskayaProduct3.data,
    'LearnProekt-product-id-1': learnProektProduct1.data,
    'LearnProekt-product-id-2': learnProektProduct2.data,
    'LearnProekt-product-id-3': learnProektProduct3.data,
    'NinetyNine-product-id-1': ninetyNineProduct1.data,
    'NinetyNine-product-id-2': ninetyNineProduct2.data,
    'SkillFactory-product-id-1': skillFactoryProduct1.data,
    'SkillFactory-product-id-2': skillFactoryProduct2.data,
    'SkillFactory-product-id-3': skillFactoryProduct3.data,
    'Sychev-product-id-1': sychevProduct1.data,
    'Sychev-product-id-2': sychevProduct2.data,
    'Sychev-product-id-3': sychevProduct3.data,
    'SKYENG-product-id-1': skyengProduct1.data,
    'Yasno-product-id-1': yasnoProduct1.data,
    'Yasno-product-id-2': yasnoProduct2.data,
    'Yasno-product-id-3': yasnoProduct3.data,
  },
  _en: {
    'nethouse-product-id-1': en_nethouseProduct1.data,
    'nethouse-product-id-2': en_nethouseProduct2.data,
    'nethouse-product-id-3': en_nethouseProduct3.data,
    'shatskaya-product-id-1': en_shatskayaProduct1.data,
    'shatskaya-product-id-2': en_shatskayaProduct2.data,
    'shatskaya-product-id-3': en_shatskayaProduct3.data,
    'LearnProekt-product-id-1': en_learnProektProduct1.data,
    'LearnProekt-product-id-2': en_learnProektProduct2.data,
    'LearnProekt-product-id-3': en_learnProektProduct3.data,
    'NinetyNine-product-id-1': en_ninetyNineProduct1.data,
    'NinetyNine-product-id-2': en_ninetyNineProduct2.data,
    'SkillFactory-product-id-1': en_skillFactoryProduct1.data,
    'SkillFactory-product-id-2': en_skillFactoryProduct2.data,
    'SkillFactory-product-id-3': en_skillFactoryProduct3.data,
    'Sychev-product-id-1': en_sychevProduct1.data,
    'Sychev-product-id-2': en_sychevProduct2.data,
    'Sychev-product-id-3': en_sychevProduct3.data,
    'SKYENG-product-id-1': en_skyengProduct1.data,
    'Yasno-product-id-1': en_yasnoProduct1.data,
    'Yasno-product-id-2': en_yasnoProduct2.data,
    'Yasno-product-id-3': en_yasnoProduct3.data,
  },
};
const sellersMap: Record<string, Record<string, TSeller>> = {
  hy: {
    nethouse: nethouseInfo.data,
    shatskaya: shatskayaInfo.data,
    LearnProekt: learnProektInfo.data,
    NinetyNine: ninetyNineInfo.data,
    SkillFactory: skillFactoryInfo.data,
    Sychev: sychevInfo.data,
    SKYENG: skyengInfo.data,
    Yasno: yasnoInfo.data,
  },
  _en: {
    nethouse: en_nethouseInfo.data,
    shatskaya: en_shatskayaInfo.data,
    LearnProekt: en_learnProektInfo.data,
    NinetyNine: en_ninetyNineInfo.data,
    SkillFactory: en_skillFactoryInfo.data,
    Sychev: en_sychevInfo.data,
    SKYENG: en_skyengInfo.data,
    Yasno: en_yasnoInfo.data,
  },
};
const sellersProductsMap: Record<string, Record<string, TProductListItem[]>> = {
  hy: {
    nethouse: nethouseProductList.data,
    shatskaya: shatskayaProductList.data,
    LearnProekt: learnProektProductList.data,
    NinetyNine: ninetyNineProductList.data,
    SkillFactory: skillFactoryProductList.data,
    Sychev: sychevProductList.data,
    SKYENG: skyengProductList.data,
    Yasno: yasnoProductList.data,
  },
  _en: {
    nethouse: en_nethouseProductList.data,
    shatskaya: en_shatskayaProductList.data,
    LearnProekt: en_learnProektProductList.data,
    NinetyNine: en_ninetyNineProductList.data,
    SkillFactory: en_skillFactoryProductList.data,
    Sychev: en_sychevProductList.data,
    SKYENG: en_skyengProductList.data,
    Yasno: en_yasnoProductList.data,
  },
};

export const getExchangeRate: QueryFunction<
  TExchangeRate | null
> = async () => {
  // const [, locale] = queryKey as string[];
  // if (locale === '_en') {
  //   try {
  //     const { data } = await axios(API_URL.EXCHANGE_RATE_URL);
  //     const result = data?.data || null;
  //
  //     return Promise.resolve(result);
  //   } catch (e) {
  //     return Promise.reject(null);
  //   }
  // } else {
  const result = {
    conversionRate: MOCK_AMD,
    validDateFrom: '2024-11-21T13:54:19.107Z',
  };

  return Promise.resolve(result);
  //}
};

export const getProductList: QueryFunction<TProductListItem[]> = async ({
  queryKey,
}) => {
  const [, exchangeRate, locale] = queryKey as string[];
  const productList = Object.keys(sellersProductsMap[locale]).reduce(
    (accum, objectKey) => {
      const responseData = sellersProductsMap[locale][objectKey];

      return [...accum, ...convertList(responseData, exchangeRate)];
    },
    [],
  );

  return Promise.resolve(productList);
};

export const getSellerProductList: QueryFunction<
  TProductListItem[],
  string[]
> = async ({ queryKey }) => {
  const [, sellerId, exchangeRate, locale] = queryKey as string[];
  const productList: TProductListItem[] = sellersProductsMap[locale][sellerId];

  if (productList) {
    const result = convertList(productList, exchangeRate);

    return Promise.resolve(result);
  }

  return Promise.reject();
};

export const getProduct: QueryFunction<TProduct, string[]> = async ({
  queryKey,
}) => {
  const [, productId, exchangeRate, locale] = queryKey as string[];
  const productData: TProduct = productsMap[locale][productId];

  return productData
    ? Promise.resolve({
        ...productData,
        price: +(productData.price / +exchangeRate).toFixed(2),
        costInRubles: productData.price,
        costInDollars: +(productData.price / +exchangeRate).toFixed(2),
        exchangeRate: +exchangeRate,
        beforePrice: +(productData.beforePrice / +exchangeRate).toFixed(2),
      })
    : Promise.reject();
};

export const getSeller: QueryFunction<TSeller, string[]> = async ({
  queryKey,
}) => {
  const [, sellerId, locale] = queryKey as string[];
  const seller = sellersMap[locale][sellerId];

  if (seller) {
    return Promise.resolve(seller);
  }

  return Promise.reject();
};

export const getOrder: QueryFunction<TOrderData, string[]> = async ({
  queryKey,
}) => {
  const [, orderId] = queryKey as string[];

  try {
    const response = await axios<TOrderResponse>(
      `/v1/processing/?orderId=${orderId}`,
    );

    // 500
    if (response.data.code === 500) return Promise.reject(response.data);
    // 404
    if (response.data.code === 404) return Promise.reject(response.data);
    // success
    if (response.data.data) return Promise.resolve(response.data.data);

    // other
    return Promise.reject(response);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const processingInitBank: MutationFunction<
  { data: string; code: number } | null,
  { email: string; phone: string; name: string; orderId: string }
> = async payload => {
  const { orderId, ...customer } = payload;

  try {
    const response = await axios({
      method: 'POST',
      url: `/v1/processing/init/bank/?id=${orderId}`,
      data: {
        customer,
      },
    } as AxiosRequestConfig);

    return Promise.resolve(response.data);
  } catch (e) {
    return Promise.reject(e);
  }
};
