import type { TProduct } from 'types';

export const mockApi_Product_1: TProduct = {
  id: 'SKYENG-product-id-1',
  sellerId: 'SKYENG',
  createDate: '2024-05-13T12:11:19.0',
  type: 'Services',
  productName: 'English lessons',
  companyName: 'SKYENG',
  logoURL: '/assets/sellers/SKYENG/logo-88x88.png',
  shortInfoText: 'Premium English',
  fullInfoText: 'Remote individual English lessons (50 minutes)',
  variants: [],
  pictures: ['/assets/sellers/SKYENG/card-1-590x590.png'],
  beforePrice: null,
  price: 990,
};
