import type { TSeller } from 'types/seller.types';

export const mockApi_seller: TSeller = {
  id: 'Sychev',
  logoURL: '/assets/sellers/Sychev/logo-88x88.png',
  bigBoard: '/assets/sellers/Sychev/logo-1440x200.png',
  companyName: 'Sychev K. I.',
  shortInfoText: 'Առցանց խորհրդատվություն հոգեբանի հետ',
  info: 'Հոգեբանի խորհրդատվություն Նեֆորմատ կլինիկայում',
};
