import type { TSeller } from 'types/seller.types';

export const mockApi_seller: TSeller = {
  id: 'NinetyNine',
  logoURL: '/assets/sellers/NinetyNine/logo-88x88.png',
  bigBoard: '/assets/sellers/NinetyNine/logo-1440x200.png',
  companyName: 'NinetyNine',
  shortInfoText: 'Online training and Author tours',
  info: 'Online workouts that will help you create the body of your dreams and Author tours that will give unforgettable emotions',
};
