import type { TProduct } from 'types/product.types';

export const mockApi_Product_1: TProduct = {
  id: 'SKYENG-product-id-1',
  sellerId: 'SKYENG',
  createDate: '2024-05-13T12:11:19.0',
  type: 'Ծառայություններ',
  productName: 'Հեռավոր անհատ',
  companyName: 'SKYENG',
  logoURL: '/assets/sellers/SKYENG/logo-88x88.png',
  shortInfoText: 'Պրեմիում անգլերեն',
  fullInfoText:
    'The session duration is 60 minutes Work experience as a psychologist - 4 years Works in approaches: CBT and AST Only works with adults. Does not work:with couples and with addictions',
  variants: [],
  pictures: ['/assets/sellers/SKYENG/card-1-590x590.png'],
  beforePrice: null,
  price: 990,
};
