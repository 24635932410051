import type { TProduct } from 'types/product.types';

export const mockApi_Product_1: TProduct = {
  id: 'NinetyNine-product-id-1',
  sellerId: 'NinetyNine',
  createDate: '2024-05-13T12:11:19.0',
  type: 'Services',
  productName: 'NUTRITION AND TRAINING Course',
  companyName: 'NinetyNine',
  logoURL: '/assets/sellers/NinetyNine/logo-88x88.png',
  shortInfoText: 'Online training and Author tours',
  fullInfoText: '4 weeks of training + 2 weeks of additional access',
  pictures: ['/assets/sellers/NinetyNine/card-1-590x590.png'],
  variants: [],
  beforePrice: null,
  price: 6000,
};
