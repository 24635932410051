import type { TProduct } from 'types/product.types';

export const mockApi_Product_1: TProduct = {
  id: 'Yasno-product-id-1',
  sellerId: 'Yasno',
  createDate: '2024-05-13T12:11:19.0',
  type: 'Ծառայություններ',
  productName: 'Առցանց վկայական 5 նիստերի համար:',
  companyName: 'Յասնո Լայվ ՍՊԸ',
  logoURL: '/assets/sellers/Yasno/logo-88x88.png',
  shortInfoText: 'Տեսախորհրդատվական ծառայություն հոգեթերապևտների հետ.',
  fullInfoText: 'Ձեր ներքին կոնֆլիկտները տեսնելու և հասկանալու հնարավորություն',
  variants: [],
  pictures: ['/assets/sellers/Yasno/card-1-590x590.png'],
  beforePrice: null,
  price: 14250,
};
