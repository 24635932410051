import type { TSeller } from 'types/seller.types';

export const mockApi_seller: TSeller = {
  id: 'shatskaya',
  logoURL: '/assets/sellers/shatskaya/logo-88x88.png',
  bigBoard: '/assets/sellers/shatskaya/logo-1440x200.png',
  companyName: 'Shatskaya K. K.',
  shortInfoText: 'Consultations on yoga therapy and yoga',
  info: 'A physical rehabilitologist, a teacher of yoga and yoga therapy.',
};
