import type { TProduct } from 'types/product.types';

export const mockApi_Product_1: TProduct = {
  id: 'LearnProekt-product-id-1',
  sellerId: 'LearnProekt',
  createDate: '2024-05-13T12:11:19.0',
  type: 'Ծառայություններ',
  productName: 'Գերմաներենի դասընթացներ զրոյից',
  companyName: 'LearnProekt',
  logoURL: '/assets/sellers/LearnProekt/logo-88x88.png',
  shortInfoText: 'Գերմաներենի դպրոց',
  fullInfoText:
    'Առցանց գրադարան A1 + «Գերմաներենի ներածական դասընթաց սկսնակների համար»» Անվճար մասնակցություն շաբաթական զրույցի և քերականության խմբակներին 8 շաբաթ շարունակ',
  variants: [],
  pictures: ['/assets/sellers/LearnProekt/card-1-590x590.png'],
  beforePrice: null,
  price: 23000,
};
