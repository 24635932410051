import cn from 'classnames';
import { useRouter } from 'next/router';
import type { MouseEvent } from 'react';
import React from 'react';
import AnimateHeight from 'react-animate-height';

import css from './menu.module.scss';

interface TProps {
  onClickCategory: (value: string) => void;
  onClose: () => void;
  isMenuOpen: boolean;
}

export const Menu = ({
  onClickCategory,
  onClose,
  isMenuOpen,
}: TProps): JSX.Element => {
  const { locale } = useRouter();

  const handleSubmit = (item: string) => (e: MouseEvent<HTMLLIElement>) => {
    e.stopPropagation();

    onClickCategory(item);
    onClose();
  };

  return (
    <div
      className={cn(
        css.overlay,
        isMenuOpen ? css.overlayShow : css.overlayHidden,
      )}
      onClick={onClose}
    >
      <AnimateHeight height={isMenuOpen ? 'auto' : 0}>
        <menu
          className={cn(
            css.wrapper,
            isMenuOpen ? css.wrapperOpen : css.wrapperClose,
          )}
        >
          <p className={css.title}>
            {locale === '_en' ? 'Category' : 'կատեգորիաներ'}
          </p>
          <hr className={css.hr} />
          <div className={css.content}>
            <ul className={css.ul}>
              {[
                {
                  name: locale === '_en' ? 'It business' : 'ՏՏ բիզնես',
                  id: 'it_business',
                },
              ].map(item => (
                <li
                  key={item.id}
                  className={css.li}
                  onClick={handleSubmit(item.name)}
                >
                  {item.name}
                </li>
              ))}
            </ul>
          </div>
        </menu>
      </AnimateHeight>
    </div>
  );
};
