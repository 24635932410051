import type { TSeller } from 'types/seller.types';

export const mockApi_seller: TSeller = {
  id: 'Yasno',
  logoURL: '/assets/sellers/Yasno/logo-88x88.png',
  bigBoard: '/assets/sellers/Yasno/logo-1440x200.png',
  companyName: 'Yasno.',
  shortInfoText: 'Video consultation service with psychotherapists.',
  info: 'A video consultation service with psychotherapists that can be used from anywhere and from any device.',
};
