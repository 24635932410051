import type { TSeller } from 'types/seller.types';

export const mockApi_seller: TSeller = {
  id: 'shatskaya',
  logoURL: '/assets/sellers/shatskaya/logo-88x88.png',
  bigBoard: '/assets/sellers/shatskaya/logo-1440x200.png',
  companyName: ' ԱՁ Քսենիա  Շացկայա Կոնստանտինի',
  shortInfoText: 'Խորհրդատվություն յոգաթերապիայի և յոգայի վերաբերյալ',
  info: 'Ֆիզիկական վերականգնման մասնագետ, յոգայի և յոգաթերապիայի ուսուցիչ։',
};
