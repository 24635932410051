import type { TProduct } from 'types/product.types';

export const mockApi_Product_3: TProduct = {
  id: 'shatskaya-product-id-3',
  sellerId: 'shatskaya',
  createDate: '2024-05-13T12:11:19.0',
  type: 'Ծառայություններ',
  productName: 'ՄԻՆԻ «ՌԵՍՈՒՐՍԻ ԴԱՍԸՆԹԱՑ»',
  companyName: ' ԱՁ Քսենիա  Շացկայա Կոնստանտինի',
  logoURL: '/assets/sellers/shatskaya/logo-88x88.png',
  shortInfoText: 'Խորհրդատվություն յոգաթերապիայի և յոգայի վերաբերյալ',
  fullInfoText: '30-ական րոպեանոց 10 դաս',
  variants: [],
  pictures: ['/assets/sellers/shatskaya/card-3-590x590.png'],
  price: 9000,
  beforePrice: null,
};
