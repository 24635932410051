import type { TProduct } from 'types/product.types';

export const mockApi_Product_1: TProduct = {
  id: 'SkillFactory-product-id-1',
  sellerId: 'SkillFactory',
  createDate: '2024-05-13T12:11:19.0',
  type: 'Services',
  productName: 'Data Analyst Course',
  companyName: 'SkillFactory',
  logoURL: '/assets/sellers/SkillFactory/logo-88x88.png',
  shortInfoText: 'Online school',
  fullInfoText:
    'Introduction to the business models of e-commerce (e-commerce) and GameDev (game development). You will consider not only theory, but also work with Google Tables, learn the basics of statistics, SQL and Python to analyze data effectively.',
  variants: [],
  pictures: ['/assets/sellers/SkillFactory/card-1-590x590.png'],
  beforePrice: null,
  price: 350000,
};
