import type { TProduct } from 'types/product.types';

export const mockApi_Product_2: TProduct = {
  id: 'nethouse-product-id-2',
  sellerId: 'nethouse',
  createDate: '2024-05-13T12:11:19.0',
  type: 'Ծառայություններ',
  productName: 'Սակագնային «Խանութ»',
  companyName: 'Nethouse',
  logoURL: '/assets/sellers/nethouse/logo-88x88.png',
  shortInfoText: 'Լիարժեք լենդինգների կոնստրուկտոր',
  fullInfoText:
    'Առցանց խանութների համար։ 1000+ ապրանքներ, անսահմանափակ թվով պատկերներ, կատալոգի ներմուծում/արտահանում, գովազդային կոդեր, հաճախորդի անձնական հաշիվ, մուտքի իրավունքի բաժանում, հաճախորդներին SMS ծանուցումներ:',
  variants: [],
  pictures: ['/assets/sellers/nethouse/card-2-590x590.png'],
  price: 1050,
  beforePrice: 1780,
};
