import { useQuery } from '@tanstack/react-query';
import { getExchangeRate } from 'api';
import { useRouter } from 'next/router';
import type { ReactNode } from 'react';
import React, { useEffect, useRef } from 'react';
import type { TExchangeRate } from 'types';

import { ROUTES } from 'constants/routes';
import type { TStoreFilter } from 'constants/store';
import { DEFAULT_FILTER, STORE } from 'constants/store';

import Footer from 'components/Footer';
import Header from 'components/Header';
import { HtmlHead } from 'components/layouts/HtmlHead';

import css from './Page.module.scss';

interface Props {
  children: ReactNode;
  fullHeight?: boolean;
  fullWidth?: boolean;
  withoutHeader?: boolean;
  withoutFooter?: boolean;
  className?: string;
  grid?: boolean;
  removeBottomPadding?: boolean;
  seo?: {
    title?: string;
    description?: string;
  };
}

const VISIBLE_HEADER_PATHS: ROUTES[] = [ROUTES.PRODUCT, ROUTES.SELLER];

export const LAYOUT_ID = 'LAYOUT_ID';

export default function Page({ children, seo }: Props): JSX.Element {
  const ref = useRef<HTMLDivElement | null>(null);
  const router = useRouter();
  const { locale } = router;

  useEffect(() => {
    if (ref.current) {
      const mainElement = ref.current as HTMLDivElement;

      mainElement.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [ref.current, router.query]);

  const { isLoading: exchangeRateLoading } = useQuery<TExchangeRate>({
    queryKey: [STORE.EXCHANGE_RATE, locale],
    queryFn: getExchangeRate,
  });
  useQuery<TStoreFilter>({
    queryKey: [STORE.FILTER],
    initialData: DEFAULT_FILTER,
  });
  const isShowHeader =
    router.pathname === ROUTES.ROOT ||
    VISIBLE_HEADER_PATHS.some(pathname => router.pathname.includes(pathname));

  if (isShowHeader) {
    if (exchangeRateLoading) {
      return null;
    }

    return (
      <>
        <HtmlHead {...seo} />
        <div id={LAYOUT_ID} className={css.wrapper}>
          <Header className={css.header} />
          <main ref={ref} className={css.main}>
            {children} <Footer className={css.footer} />
          </main>
        </div>
      </>
    );
  }

  return (
    <>
      <HtmlHead {...seo} />
      <main className={css.main}>{children}</main>
    </>
  );
}
