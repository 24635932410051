// import { useLocation } from 'react-router-dom';
// import queryString from 'query-string';
import cn from 'classnames';
import React from 'react';

import DesertSVG from '../../assets/desert.svg';
import ElectricityConnectionSVG from '../../assets/electricity-connection.svg';

import css from './errorPage.module.scss';

interface TProps {
  status: number;
}

export const ErrorPage = ({ status }: TProps): JSX.Element => {
  const isNotFoundError = +status === 404;

  return (
    <div className={css.wrapper}>
      <div
        className={cn(isNotFoundError ? css.container404 : css.container500)}
      >
        <h1 className={css.title}>
          {isNotFoundError
            ? 'Страница не найдена'
            : 'Сервер временно недоступен'}
        </h1>

        <p className={css.text}>
          {isNotFoundError
            ? 'К сожалению, страница не найдена. Попробуйте повторить запрос позже.'
            : 'Мы уже работаем над этим. Все ваши данные в порядке, и скоро вы сможете их увидеть.'}
        </p>

        {isNotFoundError ? (
          <DesertSVG className={css.img} />
        ) : (
          <ElectricityConnectionSVG className={css.img} />
        )}
      </div>
    </div>
  );
};
