import type { TProduct } from 'types/product.types';

export const mockApi_Product_3: TProduct = {
  id: 'Yasno-product-id-3',
  sellerId: 'Yasno',
  createDate: '2024-05-13T12:11:19.0',
  type: 'Ծառայություններ',
  productName: 'Առցանց խորհրդատվություն Անտոնինա Կորոլևայի հետ',
  companyName: 'Յասնո Լայվ ՍՊԸ',
  logoURL: '/assets/sellers/Yasno/logo-88x88.png',
  shortInfoText: 'Տեսախորհրդատվական ծառայություն հոգեթերապևտների հետ.',
  fullInfoText:
    'Առցանց խորհրդատվություն հոգեբան Անտոնինա Կորոլևայի հետ (50 րոպե)',
  pictures: ['/assets/sellers/Yasno/card-3-590x590.png'],
  beforePrice: null,
  variants: [],
  price: 3950,
};
