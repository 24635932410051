import type { TProduct } from 'types/product.types';

export const mockApi_Product_1: TProduct = {
  id: 'shatskaya-product-id-1',
  sellerId: 'shatskaya',
  createDate: '2024-05-13T12:11:19.0',
  type: 'Ծառայություններ',
  productName: 'Ֆասիալ մարմնամարզություն',
  companyName: ' ԱՁ Քսենիա  Շացկայա Կոնստանտինի',
  logoURL: '/assets/sellers/shatskaya/logo-88x88.png',
  shortInfoText: 'Խորհրդատվություն յոգաթերապիայի և յոգայի վերաբերյալ',
  fullInfoText:
    'Մկանային-կմախքային համակարգի հյուսվածքների մշակման վարժությունների համալիր',
  variants: [],
  pictures: ['/assets/sellers/shatskaya/card-1-590x590.png'],
  price: 4000,
  beforePrice: null,
};
