import type { TProduct } from 'types/product.types';

export const mockApi_Product_2: TProduct = {
  id: 'NinetyNine-product-id-2',
  sellerId: 'NinetyNine',
  createDate: '2024-05-13T12:11:19.0',
  type: 'Ծառայություններ',
  productName: 'Ինտենսիվ «ԿՈՆՔԻ ՀԱՏԱԿԻ ՄԿՈՒՆԵՐ»',
  companyName: 'NinetyNine',
  logoURL: '/assets/sellers/NinetyNine/logo-88x88.png',
  shortInfoText: 'Առցանց ուսուցում և հեղինակային շրջագայություններ',
  fullInfoText:
    'Ձեր անձնական հաշվում և tg-bot-ում գրանցված դասընթացներ 6 օր վերապատրաստում և',
  pictures: ['/assets/sellers/NinetyNine/card-2-590x590.png'],
  beforePrice: null,
  variants: [],
  price: 2000,
};
