/* eslint-disable */
import type { ReactNode } from 'react';
import React from 'react';

import ErrorPage from '../ErrorPage';

interface TProps {
  children: ReactNode;
}
interface TState {
  hasError: boolean;
}

export class ErrorBoundary extends React.Component<TProps, TState> {
  constructor(props: TProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: never, errorInfo: never) {
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage status={500} />;
    }

    return this.props.children;
  }
}
