import type { TProduct } from 'types/product.types';

export const mockApi_Product_2: TProduct = {
  id: 'SkillFactory-product-id-1',
  sellerId: 'SkillFactory',
  createDate: '2024-05-13T12:11:19.0',
  type: 'Ծառայություններ',
  productName: 'Թեստավորման դասընթացներ',
  companyName: 'SkillFactory',
  logoURL: '/assets/sellers/SkillFactory/logo-88x88.png',
  shortInfoText: 'Առցանց դասընթացներ',
  fullInfoText:
    'Փորձարկողների համար նախատեսված դասընթացներում դուք կսովորեք կիրառել թեստի դիզայնի տեսությունը, գրել արտադրանքի պահանջները, օգտագործել սխալների հետագծման համակարգեր, գրել պարզ կոդ և կատարել ավտոմատ փորձարկումներ:',
  pictures: ['/assets/sellers/SkillFactory/card-2-590x590.png'],
  beforePrice: null,
  variants: [],
  price: 220000,
};
