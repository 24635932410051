import type { TProductListItem } from 'types/product.types';

export const mockApi_productList: TProductListItem[] = [
  {
    id: 'LearnProekt-product-id-1',
    sellerId: 'LearnProekt',
    createDate: '2024-05-13T12:11:19.0',
    type: 'Services',
    productName: 'German language courses from scratch',
    companyName: 'LearnProekt',
    logoURL: '/assets/sellers/LearnProekt/logo-88x88.png',
    pictureURL: '/assets/sellers/LearnProekt/card-1-260x260.png',
    price: 23000,
  },
  {
    id: 'LearnProekt-product-id-2',
    sellerId: 'LearnProekt',
    createDate: '2024-05-13T12:11:19.0',
    type: 'Services',
    productName: 'German language courses for beginners A1',
    companyName: 'LearnProekt',
    logoURL: '/assets/sellers/LearnProekt/logo-88x88.png',
    pictureURL: '/assets/sellers/LearnProekt/card-1-260x260.png',
    price: 23000,
  },
  {
    id: 'LearnProekt-product-id-3',
    sellerId: 'LearnProekt',
    createDate: '2024-05-13T12:11:19.0',
    type: 'Services',
    productName: 'German language courses for continuing A2',
    companyName: 'LearnProekt',
    logoURL: '/assets/sellers/LearnProekt/logo-88x88.png',
    pictureURL: '/assets/sellers/LearnProekt/card-1-260x260.png',
    price: 23000,
  },
];
