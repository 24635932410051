import type { TProduct } from 'types/product.types';

export const mockApi_Product_2: TProduct = {
  id: 'shatskaya-product-id-2',
  sellerId: 'shatskaya',
  createDate: '2024-05-13T12:11:19.0',
  type: 'Ծառայություններ',
  productName: 'ԱՌՈՂՋ ՄԵՋ',
  companyName: ' ԱՁ Քսենիա  Շացկայա Կոնստանտինի',
  logoURL: '/assets/sellers/shatskaya/logo-88x88.png',
  shortInfoText: 'Խորհրդատվություն յոգաթերապիայի և յոգայի վերաբերյալ',
  fullInfoText: 'Մարզումներ առողջ մեջքի համար',
  pictures: ['/assets/sellers/shatskaya/card-2-590x590.png'],
  price: 6000,
  beforePrice: null,
  variants: [],
};
