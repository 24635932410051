import type { TProduct } from 'types/product.types';

export const mockApi_Product_3: TProduct = {
  id: 'Sychev-product-id-3',
  sellerId: 'Sychev',
  createDate: '2024-05-13T12:11:19.0',
  type: 'Ծառայություններ',
  productName: 'Առցանց խորհրդատվություն',
  companyName: 'Sychev K. I.',
  logoURL: '/assets/sellers/Sychev/logo-88x88.png',
  shortInfoText: 'Առցանց խորհրդատվություն հոգեբանի հետ',
  fullInfoText:
    'Նիստի տևողությունը՝ 55 րոպե Աշխատանքային փորձ 3 տարի Աշխատում է մոտեցմամբ՝ ՀՍՏ Ում հետ է այն աշխատում՝ մեծահասակների հետ  Չի աշխատում՝ զույգերով, կախվածությամբ',
  pictures: ['/assets/sellers/Sychev/card-3-590x590.png'],
  beforePrice: null,
  variants: [],
  price: 3000,
};
