import type { TProduct } from 'types/product.types';

export const mockApi_Product_1: TProduct = {
  id: 'nethouse-product-id-1',
  sellerId: 'nethouse',
  createDate: '2024-05-13T12:11:19.0',
  type: 'Services',
  productName: 'The "Website" tariff',
  companyName: 'Nethouse',
  logoURL: '/assets/sellers/nethouse/logo-88x88.png',
  shortInfoText:
    'The designer of websites, online stores and landing pages. Three tariff plans to choose from.',
  fullInfoText:
    'For business card sites and landing pages. Up to 10 products, up to 400 images. Statistical data, payment acceptance, notifications in Telegram. The price is for one month.',
  variants: [],
  pictures: ['/assets/sellers/nethouse/card-1-590x590.png'],
  price: 500,
  beforePrice: 1350,
};
