import type { TProduct } from 'types/product.types';

export const mockApi_Product_3: TProduct = {
  id: 'nethouse-product-id-3',
  sellerId: 'nethouse',
  createDate: '2024-05-13T12:11:19.0',
  type: 'Services',
  productName: 'The "Professional" tariff.',
  companyName: 'Nethouse',
  logoURL: '/assets/sellers/nethouse/logo-88x88.png',
  shortInfoText:
    'Designer of websites, online stores and landing pages. Three tariff plans to choose from.',
  fullInfoText:
    'For those who want to get the maximum functionality of the service. 5000+ products, unlimited number of images, priority support, integration with Ozon and Wildberries, HTML block with scripts, payment via SBP. The price is for one month.',
  variants: [
    {
      id: 'super-id-1',
      name: '1',
      value: 'шт.',
    },
    {
      id: 'super-id-2',
      name: '2',
      value: 'шт.',
    },
    {
      id: 'super-id-3',
      name: '3',
      value: 'шт.',
    },
    {
      id: 'super-id-4',
      name: '4',
      value: 'шт.',
    },
    {
      id: 'super-id-5',
      name: '5',
      value: 'шт.',
    },
  ],
  pictures: ['/assets/sellers/nethouse/card-3-590x590.png'],
  price: 3500,
  beforePrice: 4100,
};
