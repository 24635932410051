import type { TSeller } from 'types/seller.types';

export const mockApi_seller: TSeller = {
  id: 'Yasno',
  logoURL: '/assets/sellers/Yasno/logo-88x88.png',
  bigBoard: '/assets/sellers/Yasno/logo-1440x200.png',
  companyName: 'Յասնո Լայվ ՍՊԸ',
  shortInfoText: 'Տեսախորհրդատվական ծառայություն հոգեթերապևտների հետ.',
  info: 'Տեսախորհրդատվական ծառայություն հոգեթերապևտների հետ, որը կարող է օգտագործվել ցանկացած վայրից և ցանկացած սարքից:',
};
