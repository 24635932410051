import type { TProduct } from 'types/product.types';

export const mockApi_Product_2: TProduct = {
  id: 'SkillFactory-product-id-1',
  sellerId: 'SkillFactory',
  createDate: '2024-05-13T12:11:19.0',
  type: 'Services',
  productName: 'QA courses',
  companyName: 'SkillFactory',
  logoURL: '/assets/sellers/SkillFactory/logo-88x88.png',
  shortInfoText: 'Online school',
  fullInfoText:
    'In the courses for QA, you will learn how to apply the theory of test design, make product requirements, use bug tracking systems, write simple code and do autotests.',
  pictures: ['/assets/sellers/SkillFactory/card-2-590x590.png'],
  beforePrice: null,
  variants: [],
  price: 220000,
};
