import type { TSeller } from 'types/seller.types';

export const mockApi_seller: TSeller = {
  id: 'SKYENG',
  logoURL: '/assets/sellers/SKYENG/logo-88x88.png',
  bigBoard: '/assets/sellers/SKYENG/logo-1440x200.png',
  companyName: 'SKYENG',
  shortInfoText: 'Premium English',
  info: 'Remote individual English classes',
};
