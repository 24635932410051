import type { TProduct } from 'types/product.types';

export const mockApi_Product_2: TProduct = {
  id: 'Sychev-product-id-1',
  sellerId: 'Sychev',
  createDate: '2024-05-13T12:11:19.0',
  type: 'Services',
  productName: 'Online consultation',
  companyName: 'Sychev K. I.',
  logoURL: '/assets/sellers/Sychev/logo-88x88.png',
  shortInfoText: 'Online psychological consultation',
  fullInfoText:
    'Session duration - 50 minutes Work experience of 6 years. It works in the following approaches: CBT, AST, CFT Only works with adults. Does not work with pairs and dependencies',
  pictures: ['/assets/sellers/Sychev/card-2-590x590.png'],
  beforePrice: null,
  variants: [],
  price: 6000,
};
