import ArCaLogoSVG from 'assets/arca-logo.svg';
import MaestroLogoSVG from 'assets/maestro-logo.svg';
import McLogoSVG from 'assets/mc-logo.svg';
import VisaLogoSVG from 'assets/visa-logo.svg';
import cn from 'classnames';
import { useRouter } from 'next/router';
import React, { useState } from 'react';

import { ROUTES } from 'constants/routes';

import { Modal } from '../Modal';

import css from './footer.module.scss';

interface TProps {
  className: string;
}

export const Footer = ({ className }: TProps): JSX.Element => {
  const router = useRouter();
  const { locale } = router;
  const isProductPage = router.pathname.includes(ROUTES.PRODUCT);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isContactsModalOpen, setIsContactsModalOpen] = useState(false);

  return (
    <footer
      className={cn(className, css.wrapper, isProductPage && css.productPage)}
    >
      <div className={css.links}>
        <a
          target="_blank"
          href={`${ROUTES.DOCS}/${locale}/terms-of-use`}
          rel="noreferrer"
        >
          <span>
            {locale === '_en' ? 'Terms of sale,' : 'Վաճառքի պայմանները'}
          </span>
        </a>
        <a
          target="_blank"
          href={`${ROUTES.DOCS}/${locale}/conditions-of-use`}
          rel="noreferrer"
        >
          <span>
            {locale === '_en' ? 'Conditions of Use,' : 'Օգտագործման պայմանները'}
          </span>{' '}
        </a>
        <a
          target="_blank"
          href={`${ROUTES.DOCS}/${locale}/policy`}
          rel="noreferrer"
        >
          <span>
            {locale === '_en'
              ? 'Privacy Policy,'
              : 'Գաղտնիության քաղաքականություն'}
          </span>{' '}
        </a>
        <span onClick={(): void => setIsModalOpen(true)}>
          {locale === '_en' ? 'About' : 'Մեր մասին'}
        </span>
        <span onClick={(): void => setIsContactsModalOpen(true)}>
          {locale === '_en' ? 'Contacts' : 'Հետադարձ կապ'}
        </span>
      </div>
      <div className={css.icons}>
        <ArCaLogoSVG viewBox="0 0 216 60" className={css.svg} />
        <MaestroLogoSVG viewBox="0 0 48 30" className={css.svg} />
        <McLogoSVG viewBox="0 0 48 30" className={css.svg} />
        <VisaLogoSVG viewBox="0 0 48 30" className={css.svg} />
      </div>
      <Modal
        className={css.modal}
        isOpen={isModalOpen}
        onClose={(): void => {
          setIsModalOpen(false);
        }}
      >
        {locale === '_en' ? (
          <div>
            <p>GG INVESTMENTS LLC</p>
            <p>Registration</p>
            <p>number: 286.110.1229416 / 2022-04-07, Tax ID: 02859123</p>
            <p>Address: office 367, 5A Vardanants Str., Yerevan, Armenia</p>
            <p>TIN: 02859123</p>
            <p>
              Email:{' '}
              <a
                className={css.link}
                href="mailto:partnership@gginvestments.am"
              >
                partnership@gginvestments.am
              </a>
            </p>
            <p>
              <a className={css.link} href="tel:+37498599546">
                +374 98 599546
              </a>
            </p>
          </div>
        ) : (
          <div>
            <p>«ՋԻ ՋԻ ԻՆՎԵՍՏՄԸՆԹՍ» ՍՊԸ</p>
            <p>Գրանցման համար' 286.110.1229416 / 2022-04-07</p>
            <p>ՀՎՀՀ' 02859123</p>
            <p>
              Գտնվելու վայրը' Հայաստան, Երեվան, Կենտրոն, Վարդանանց փ., 5 ա, բն.
              367 տարածք
            </p>
            <p>
              էլ. փոստ'{' '}
              <a
                className={css.link}
                href="mailto:partnership@gginvestments.am"
              >
                partnership@gginvestments.am
              </a>
            </p>
            <p>
              Հեռախոսահամար'{' '}
              <a className={css.link} href="tel:+37498599546">
                +374 98 599546
              </a>
            </p>
          </div>
        )}
      </Modal>
      <Modal
        className={css.modal}
        isOpen={isContactsModalOpen}
        onClose={(): void => {
          setIsContactsModalOpen(false);
        }}
      >
        {locale === '_en' ? (
          <div>
            <p>
              For questions related to all services presented on our platform,
              you can get detailed feedback from our specialists{' '}
              <a className={css.link} href="mailto:armmp@gginvestments.am">
                armmp@gginvestments.am
              </a>
            </p>
          </div>
        ) : (
          <div>
            <p>
              Մեր հարթակում ներկայացված բոլոր ծառայությունների հետ կապված
              հարցերի դեպքում կարող եք մանրամասն հետադարձ կապ ստանալ մեր
              մասնագետներից{' '}
              <a className={css.link} href="mailto:armmp@gginvestments.am">
                armmp@gginvestments.am
              </a>
            </p>
          </div>
        )}
      </Modal>
    </footer>
  );
};
