import { useRouter } from 'next/router';
import { useEffect } from 'react';

import css from './localeSwitcher.module.scss';

export function LocaleSwitcher(): JSX.Element {
  const router = useRouter();
  const { pathname, asPath, query, locale } = router;

  useEffect(() => {
    if (localStorage.getItem('locale') === 'en') localStorage.clear();
    if (localStorage.getItem('locale')) {
      router.push({ pathname, query }, asPath, {
        locale: localStorage.getItem('locale'),
      });
    }
  }, []);

  const handleToggleLocale = (): void => {
    const localeValue = locale === '_en' ? 'hy' : '_en';
    localStorage.setItem('locale', localeValue);
    router.push({ pathname, query }, asPath, {
      locale: localeValue,
    });
  };

  return (
    <ul className={css.wrapper}>
      <li onClick={(): void => handleToggleLocale()}>
        {locale === '_en' ? 'hy' : 'en'}
      </li>
    </ul>
  );
}
