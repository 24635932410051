import type { TProduct } from 'types/product.types';

export const mockApi_Product_3: TProduct = {
  id: 'Yasno-product-id-3',
  sellerId: 'Yasno',
  createDate: '2024-05-13T12:11:19.0',
  type: 'Services',
  productName: 'Online consultation with Antonina Koroleva',
  companyName: 'Yasno.',
  logoURL: '/assets/sellers/Yasno/logo-88x88.png',
  shortInfoText: 'Video consultation service with psychotherapists.',
  fullInfoText:
    'Online consultation with psychologist Antonina Koroleva (50 minutes)',
  pictures: ['/assets/sellers/Yasno/card-3-590x590.png'],
  beforePrice: null,
  variants: [],
  price: 3950,
};
