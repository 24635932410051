import type { TProductListItem } from 'types/product.types';

export const mockApi_productList: TProductListItem[] = [
  {
    id: 'SKYENG-product-id-1',
    sellerId: 'SKYENG',
    createDate: '2024-05-13T12:11:19.0',
    type: 'Ծառայություններ',
    productName: 'Հեռավոր անհատ',
    companyName: 'SKYENG',
    logoURL: '/assets/sellers/SKYENG/logo-88x88.png',
    pictureURL: '/assets/sellers/SKYENG/card-1-260x260.png',
    price: 990,
  },
];
