import type { TProductListItem } from 'types/product.types';

export const mockApi_productList: TProductListItem[] = [
  {
    id: 'Yasno-product-id-1',
    sellerId: 'Yasno',
    createDate: '2024-05-13T12:11:19.0',
    type: 'Services',
    productName: 'Online certificate for 5 sessions',
    companyName: 'Yasno.',
    logoURL: '/assets/sellers/Yasno/logo-88x88.png',
    pictureURL: '/assets/sellers/Yasno/card-1-260x260.png',
    price: 14250,
  },
  {
    id: 'Yasno-product-id-2',
    sellerId: 'Yasno',
    createDate: '2024-05-13T12:11:19.0',
    type: 'Services',
    productName: 'Online consultation with Artem Zabaluev',
    companyName: 'Yasno.',
    logoURL: '/assets/sellers/Yasno/logo-88x88.png',
    pictureURL: '/assets/sellers/Yasno/card-2-260x260.png',
    price: 2850,
  },
  {
    id: 'Yasno-product-id-3',
    sellerId: 'Yasno',
    createDate: '2024-05-13T12:11:19.0',
    type: 'Services',
    productName: 'Online consultation with Antonina Koroleva',
    companyName: 'Yasno.',
    logoURL: '/assets/sellers/Yasno/logo-88x88.png',
    pictureURL: '/assets/sellers/Yasno/card-3-260x260.png',
    price: 3950,
  },
];
