import type { TProduct } from 'types/product.types';

export const mockApi_Product_2: TProduct = {
  id: 'Yasno-product-id-1',
  sellerId: 'Yasno',
  createDate: '2024-05-13T12:11:19.0',
  type: 'Ծառայություններ',
  productName: 'Առցանց խորհրդատվություն Արտեմ Զաբալուևի հետ',
  companyName: 'Յասնո Լայվ ՍՊԸ',
  logoURL: '/assets/sellers/Yasno/logo-88x88.png',
  shortInfoText: 'Տեսախորհրդատվական ծառայություն հոգեթերապևտների հետ.',
  fullInfoText: 'Առցանց խորհրդատվություն հոգեբան Արտեմ Զաբալուևի հետ (50 րոպե)',
  pictures: ['/assets/sellers/Yasno/card-2-590x590.png'],
  beforePrice: null,
  variants: [],
  price: 2850,
};
