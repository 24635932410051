import type { TProduct } from 'types/product.types';

export const mockApi_Product_3: TProduct = {
  id: 'nethouse-product-id-3',
  sellerId: 'nethouse',
  createDate: '2024-05-13T12:11:19.0',
  type: 'Ծառայություններ',
  productName: 'Սակագնային «Պրոֆեսիոնալ»',
  companyName: 'Nethouse',
  logoURL: '/assets/sellers/nethouse/logo-88x88.png',
  shortInfoText: 'Լիարժեք լենդինգների կոնստրուկտոր',
  fullInfoText:
    'Նրանց համար, ովքեր ցանկանում են ստանալ ծառայության առավելագույն ֆունկցիոնալությունը: 5000+ արտադրանք, անսահմանափակ թվով պատկերներ, առաջնահերթ աջակցություն, ինտեգրում Ozon-ի և Wildberries-ի հետ, HTML բլոկ սկրիպտներով, վճարում SBP-ի միջոցով:',
  variants: [
    {
      id: 'super-id-1',
      name: '1',
      value: 'шт.',
    },
    {
      id: 'super-id-2',
      name: '2',
      value: 'шт.',
    },
    {
      id: 'super-id-3',
      name: '3',
      value: 'шт.',
    },
    {
      id: 'super-id-4',
      name: '4',
      value: 'шт.',
    },
    {
      id: 'super-id-5',
      name: '5',
      value: 'шт.',
    },
  ],
  pictures: ['/assets/sellers/nethouse/card-3-590x590.png'],
  price: 3500,
  beforePrice: 4100,
};
