import type { TProduct } from 'types/product.types';

export const mockApi_Product_3: TProduct = {
  id: 'SkillFactory-product-id-3',
  sellerId: 'SkillFactory',
  createDate: '2024-05-13T12:11:19.0',
  type: 'Ծառայություններ',
  productName: 'Python մշակողների դասընթաց',
  companyName: 'SkillFactory',
  logoURL: '/assets/sellers/SkillFactory/logo-88x88.png',
  shortInfoText: 'Առցանց դասընթացներ',
  fullInfoText:
    'Դարձեք Python-ի ծրագրավորող՝ տիրապետեք ասինխրոն ծրագրավորմանը և ընդամենը 3 ամիս ուսուցումից հետո դարձեք հաջողակ թեկնածու աշխատանք փնտրելիս:',
  pictures: ['/assets/sellers/SkillFactory/card-3-590x590.png'],
  beforePrice: null,
  variants: [],
  price: 350000,
};
