import type { TProduct } from 'types/product.types';

export const mockApi_Product_3: TProduct = {
  id: 'shatskaya-product-id-3',
  sellerId: 'shatskaya',
  createDate: '2024-05-13T12:11:19.0',
  type: 'Services',
  productName: 'Mini "RESOURCE COURSE"',
  companyName: 'Shatskaya K. K.',
  logoURL: '/assets/sellers/shatskaya/logo-88x88.png',
  shortInfoText: 'Consultations on yoga therapy and yoga',
  fullInfoText: '10 lessons of 30 minutes each',
  variants: [],
  pictures: ['/assets/sellers/shatskaya/card-3-590x590.png'],
  price: 9000,
  beforePrice: null,
};
